import { CloudStreamAPI } from '@zerolight-core/libzl';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setStreamSettings, setStreamWarning } from '../reducer/streamSlice';
import { RootState } from '../store';

export const useToggleStreamMute = (): [
	boolean,
	(cloudStream: CloudStreamAPI, muteStatus?: boolean, initialRequest?: boolean) => void,
] => {
	const audioEnabledState = useSelector((state: RootState) => state.stream.settings.audioEnabled);

	const dispatch = useDispatch();
	const handleStreamToggle = useCallback(
		(cloudStream: CloudStreamAPI, muteStatus?: boolean, initialRequest?: boolean) => {
			let audioIsPossible = false;
			if (typeof muteStatus === 'boolean') {
				// dispatch(setStreamSettings({ audioEnabled: muteStatus }));

				cloudStream.setAudioState(
					muteStatus,
					{ stereo: true },
					(_a?: any, audioState?: any, _error?: any) => {
						audioIsPossible = audioState?.supportedByStreamOutput;

						dispatch(
							setStreamSettings({
								audioEnabled: muteStatus,
								audioIsPossible,
							}),
						);
					},
				);
			} else {
				cloudStream.setAudioState(
					!audioEnabledState,
					{ stereo: true },
					(_a?: any, audioState?: any, _error?: any) => {
						audioIsPossible = audioState?.supportedByStreamOutput;

						dispatch(
							setStreamSettings({
								audioEnabled: !audioEnabledState,
								audioIsPossible,
							}),
						);
					},
				);
			}

			if (initialRequest && !audioIsPossible) {
				dispatch(
					setStreamWarning({
						msg: 'Audio unavailable due to network conditions.  Click to close.',
					}),
				);
			}
		},
		[dispatch, audioEnabledState],
	);

	return [audioEnabledState, handleStreamToggle];
};
