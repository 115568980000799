// eslint-disable-next-line import/no-unresolved
import { CloudStreamAPI } from '@zerolight-core/libzl/types/src/globals';

import { useEffect, useState, useRef, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import { Icon } from './Icon';
import { useToggleStreamMute } from '../hooks/useToggleStreamMute';
import { setStreamSettings } from '../reducer/streamSlice';
import { RootState } from '../store';

const VolumeSliderContainer = styled.div`
	display: flex;
	flex-direction: row
	width: 150px;
	height: 50px;
	place-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 6px 10px;
	gap: 0.5rem;
`;

const VolumeIcon = styled(Icon)<{ $enabled: boolean }>`
	color: ${({ theme, $enabled }) => ($enabled ? theme.colors.secondary : 'lightgrey')};
`;

export const Volume: React.FC<{
	cloudStream: CloudStreamAPI;
}> = ({ cloudStream }) => {
	const [audioMuteState, handleStreamMuteToggle] = useToggleStreamMute();
	const volume = useSelector((state: RootState) => state.stream.settings.volume);
	const audioIsPossible = useSelector(
		(state: RootState) => state.stream.settings.audioIsPossible,
	);
	const [debounceVol, setVol] = useState(volume);

	const dispatch = useDispatch();
	const ref = useRef<HTMLVideoElement | null>(null);

	const handleSetVolume = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setVol(event.target.valueAsNumber);
	}, []);

	const toggleMute = useCallback(
		() => handleStreamMuteToggle(cloudStream),
		[cloudStream, handleStreamMuteToggle],
	);

	useEffect(() => {
		ref.current = document.querySelector('.zl-stream-wrapper video');
	}, []);

	useDebounce(
		() => {
			if (
				!ref.current ||
				ref.current?.volume === undefined ||
				ref.current?.volume === debounceVol ||
				volume === debounceVol
			) {
				return;
			}

			dispatch(setStreamSettings({ volume: debounceVol, audioEnabled: debounceVol > 0 }));
			ref.current.volume = debounceVol;
		},
		500,
		[debounceVol],
	);

	useEffect(() => {
		handleStreamMuteToggle(cloudStream, audioMuteState);
	}, [audioMuteState, cloudStream, handleStreamMuteToggle]);

	return (
		<VolumeSliderContainer
			title={audioIsPossible ? '' : 'Audio unavailable due to network conditions.'}
		>
			<VolumeIcon
				icon={audioMuteState ? 'speaker' : 'speakerMute'}
				size={32}
				$enabled={audioIsPossible}
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onClick={audioIsPossible ? toggleMute : () => {}}
			/>
			<input
				disabled={!audioIsPossible}
				type="range"
				min={0}
				max={1}
				step={0.02}
				value={debounceVol}
				onChange={handleSetVolume}
			/>
		</VolumeSliderContainer>
	);
};
