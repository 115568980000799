import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorType = 'brokenLink' | 'timeout' | 'streamError';

// possible warnings from the CAS about why we might have been filtered
export enum PossibleFilterWarnings {
	HighPing = 'pingTooHighFor3D',
	LowBandwidth = 'bandwidthTooLowFor3D',
	LoadManagement = 'filteredForLoadManagement',
	BrowserNotSupported = 'browserNotSupportedFor3D',
}

export const ErrorHints = Object.values(PossibleFilterWarnings);
export type ErrorHint = typeof ErrorHints;

export type errorPayload = {
	type: ErrorType | null;
	warnings?: ErrorHint;
};

export type warningPayload = {
	msg: string;
};

export interface StreamSlice {
	loading: boolean;
	error: errorPayload;
	streamState: string;
	settings: {
		audioEnabled: boolean;
		audioIsPossible: boolean;
		showControls: boolean;
		volume: number;
	};
	warning: warningPayload;
}
export type loadingPayload = {
	loading: boolean;
};

export type settingsPayload = {
	audioEnabled?: boolean;
	audioIsPossible?: boolean;
	showControls?: boolean;
	volume?: number;
};

export type streamStatePayload = {
	streamState: string;
};

const initialState: StreamSlice = {
	loading: false,
	error: {
		type: null,
	},

	streamState: 'Disconnected',
	settings: {
		audioEnabled: false,
		audioIsPossible: false,
		showControls: false,
		volume: 0.5,
	},
	warning: { msg: '' },
};

export const streamSlice = createSlice({
	name: 'stream',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<loadingPayload>) => {
			state.loading = action.payload.loading;
		},
		setError: (state, action: PayloadAction<errorPayload>) => {
			state.error = action.payload;
		},
		setStreamSettings: (state, action: PayloadAction<settingsPayload>) => {
			state.settings = { ...state.settings, ...action.payload };
		},
		setStreamState: (state, action: PayloadAction<streamStatePayload>) => {
			state.streamState = action.payload.streamState;
		},
		setStreamWarning: (state, action: PayloadAction<warningPayload>) => {
			state.warning = action.payload;
		},
	},
});

export const { setLoading, setError, setStreamSettings, setStreamState, setStreamWarning } =
	streamSlice.actions;

export const streamReducer = streamSlice.reducer;
